<template>
  <CContainer class="d-flex content-center min-vh-100">
    <CRow>
      <CCol>
        <CCardGroup>
          <CCard class="p-4" style="min-width:300px; max-width:300px; background-color:#3c2468; border:none" 
            text-color="white">
            <CCardBody>
              <CForm>
                <h1 style="color:white; font-size:26px;">Gesto Vapp</h1>
                <p class="text-muted" style="color:white">Portal Trabajador</p>
                <!--<CInput
                  placeholder="Rut Empresa"
                  autocomplete="Rut Empresa"
                  v-model="usuario.rutEmpresa"
                  :is-valid="rutValidado"
                  @blur="validarRut"
                  @keyup.enter.native="iniciarSesion"
                ></CInput>-->
                <CInput
                  placeholder="Rut Trabajador"
                  autocomplete="off"
                  v-model="usuario.username"
                  @keyup="formatearIngresoRut"
                  @keyup.enter.native="iniciarSesion"
                ></CInput>
                <CInput
                  v-show="agregarPsw"
                  placeholder="Contraseña"
                  type="password"
                  autocomplete="off"
                  v-model="usuario.passw"
                  @keyup.enter.native="iniciarSesion"></CInput>
                  
                <CRow 
                  v-show="agregarPsw">
                  <CCol col="12" class="text-right mb-2"><a href="#" @click="goReestablecerPsw">He olvidado la contraseña</a></CCol>
                </CRow>
                <CRow>
                  <CCol col="12" class="text-left">
                    <CAlert style="max-width:250px" closeButton v-show="showMensaje" :color="tipoMensaje">{{mensajeLogin}}</CAlert>
                  </CCol>
                </CRow>
                <CRow v-show="!showMensajeCambioPass && !showMensajeRutNoConfig">
                    <CCol col="12" class="text-rigth" v-show="isLogin">
                    <center>
                      <img src="/img/logoRotate.gif" size="custom-size" :height="60" class="imgr mt-3 mb-3" /> Iniciando Sesión...
                    </center>
                    </CCol>
                  <CCol col="12" class="text-rigth" v-show="!isLogin">
                    <CButton block shape="pill" style="background-color:#0b7689;color:white;" class="px-4" @click="iniciarSesion">Ingresar</CButton>
                  </CCol>
                </CRow>
                <hr>
                <CRow>
                    <CCol col="12">
                      <CButton block shape="pill" class="btnPrincipalVapp mt-2" @click="cambioPortal">Ir a portal usuarios</CButton>
                    </CCol>
                  </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
  import cons from '@/const'
  import axios from "axios"
  
    import * as util from '@/views/utils/utilsFunctions'

  export default {
    name: 'LoginTrabajador',
    
    data () {
      return {
        urlBase: cons.port+"://" + cons.ipServer,

        existeTokenLogin: false,
        isLogin: false,
        agregarPsw: false,
        
        //expiracionCookie: "8h",
        enviandoSolicitud: false,
        solicitudEnviada: false,
        msjSolicitandoCambio: "",

        tipoMensaje: "success",
        showMensaje : false,
        mensajeLogin: "",
        showMensajeCambioPass: false,
        msjCambiarPass: "Su contraseña está por defecto, Le recomendamos cambiar la contraseña por seguridad",
        cambiarPss: false,

        showMensajeRutNoConfig: false,
        msjRutNoConfig: "",
        rutValidarRegistrar: false,

        rutValidado: false,

        usuario: {
          username: ("token" in this.$route.query)?this.$route.query.user:null,
          passw:null,
          tokenSesion: ("token" in this.$route.query)?this.$route.query.token:null
        },

        logueoResp: null,
        rutRegistrar: null,
        ingresarRut: false
      }
    },
    beforeMount() {
      
      cons.puertoQa = ("port" in this.$route.query) ? this.$route.query.port : "",
      this.validarSesion()

      if("token" in this.$route.query){
        this.existeTokenLogin = true
        this.agregarPsw = true
        this.mostrarNotificaciones(2, "Por favor ingrese su contraseña nueva")
        
      }
      //this.$route.query.token
    },
    methods: {
        cambioPortal: function(){
            this.$router.push('login')///pages/login
        },
        goReestablecerPsw: function(){
            this.$router.push('ReestablecerPsw?user=trabajador')
        },
        validarRutRegistrar: function(event){
            var rutCompleto = this.rutRegistrar
                
            if(rutCompleto == "" || rutCompleto == null){
            this.rutValidarRegistrar = false
            } else{

                rutCompleto = rutCompleto.replace("‐","-");

                if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)){
                    this.rutValidarRegistrar =  false;
                    return
                }

                var tmp = rutCompleto.split('-');
                var digv = tmp[1]; 
                var rut = tmp[0];

                if ( digv == 'K' ) digv = 'k' ;

                this.rutValidarRegistrar = (this.validarDv(rut) == digv )
            }
        },
        iniciarSesion: function(event){
        console.log("asdasdasd", event.isTrusted)
        if(event.isTrusted == false){
          return
        }
        if(!this.rutValidado){
            this.mostrarNotificaciones(2, "Ingrese su rut correctamente para continuar porfavor")
            return
        }
        
        this.isLogin = true
        var url =  this.urlBase + "/log_in_trabajador/"

        if(this.usuario.username == null || this.usuario.username == ""){
            this.showMensaje = true
            this.mensajeLogin = "Ingrese correctamente sus datos"
            this.tipoMensaje = "danger"
        }else{
            if(this.rutValidado){
                this.showMensaje = true
                this.mensajeLogin = "Ingrese correctamente el rut de la empresa"
                this.tipoMensaje = "danger"
            }

            this.showMensaje = false
            axios({
                method: "POST", 
                "url": url,
                "data": {
                    usuario: {
                        username: this.usuario.username.replace(/\./g, ''),
                        passw: this.usuario.passw,
                        tokenSesion: this.usuario.tokenSesion
                    }
                    
                }
            }).then(result => {
              if(result.data.solicitudContraseña){
                this.mostrarNotificaciones(2, result.data.mensaje)
              } else{
                if(result.data.solicitarContrasenia){
                  this.mostrarNotificaciones(2, result.data.mensaje)
                  this.agregarPsw = true
                }
                else{
                  this.logueoResp = result.data.userLog
                  this.loguearUser()
                }
              }
              this.isLogin = false
                
            }, error => {
                this.mostrarNotificaciones(3, error.response.data.mensaje)
                this.isLogin = false
                
            })
            .finally(() => {
            });
          
        }
        
      },
      loguearUser: function(){

        let cookieUsername = this.$cookie.get('userLogginToken')
        var uslog = this.logueoResp

        if(cookieUsername === null || cookieUsername === ""){

          this.$cookie.set("userLogginToken", JSON.stringify(uslog), { expires: this.logueoResp.fechaExpToken });

        } else {

          if(uslog.tokenUsuario != JSON.parse(cookieUsername).tokenUsuario){
            this.$cookie.set("userLogginToken", JSON.stringify(uslog), { expires: this.logueoResp.fechaExpToken });
          }

        }
        this.mostrarNotificaciones(1, ("Bienvenido " + this.logueoResp.nombreTrabajador.split(" ")[0]))
        cons.rutaRedireccion = this.logueoResp.rutaRedireccion
        
        this.$router.push(this.logueoResp.rutaRedireccion);

      },

      validarSesion: function(){
        let cookieUsername = this.$cookie.get('userLogginToken')

        if(cookieUsername !== null ){
          
          this.$router.push(JSON.parse(cookieUsername).rutaRedireccion);
        }    
      },

      /*validarRut:function(event){

        var rutCompleto = this.usuario.username
               
        if(rutCompleto == "" || rutCompleto == null){
          this.rutValidado = false
        } else{

          rutCompleto = rutCompleto.replace("‐","-");

          if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)){
              this.rutValidado =  false;
              return
          }

          var tmp = rutCompleto.split('-');
          var digv = tmp[1]; 
          var rut = tmp[0];

          if ( digv == 'K' ) digv = 'k' ;

          this.rutValidado = (this.validarDv(rut) == digv )
        }
      },
      validarDv: function(T){
        var M=0,S=1;
        for(;T;T=Math.floor(T/10))
            S=(S+T%10*(9-M++%6))%11;
        return S?S-1:'k';
      },*/
      
      formatearIngresoRut: function(){
          this.usuario.username = util.formatearRut(this.usuario.username)//this.formatoRut(this.usuario.username)
          this.rutValidado = util.validarRut(this.usuario.username)//this.validarRut()
      },
      /*formatoRut: function(rut){
          var sRut1 = rut.replace(/\./g,"").replace(/-/g,"");      //contador de para saber cuando insertar el . o la -
          var nPos = 0; //Guarda el rut invertido con los puntos y el guión agregado
          var sInvertido = ""; //Guarda el resultado final del rut como debe ser
          var sRut = "";
          for(var i=(sRut1.length-1); i>=0; i-- ){
              sInvertido += sRut1.charAt(i);
              if (i == sRut1.length - 1 )
                  sInvertido += "-";
              else if (nPos == 3)
              {
                  sInvertido += ".";
                  nPos = 0;
              }
              nPos++;
          }
          for(var j = sInvertido.length - 1; j>= 0; j-- )
          {
              if (sInvertido.charAt(sInvertido.length - 1) != ".")
                  sRut += sInvertido.charAt(j);
              else if (j != sInvertido.length - 1 )
                  sRut += sInvertido.charAt(j);
          }
          //Pasamos al campo el valor formateado
          //rut.value = sRut.toUpperCase();
          return sRut.toUpperCase();
      },*/


    }
  }
</script>

<style scoped>
.btnPrincipalVapp:hover{
  background-color: #1e1e1e !important;
  color: #fff !important;
}
.imgr{
  -webkit-animation: 3s rotate linear infinite !important;
  animation: 3s rotate  linear infinite !important;
  -webkit-transform-origin: 50% 50% !important;
  transform-origin: 50% 50% !important;
}
</style>